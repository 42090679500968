import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { logout } from '../../store/actions/AuthActions';
import { isAuthenticated } from '../../store/selectors/AuthSelectors';
import Dropdown from 'react-bootstrap/Dropdown';
import { Modal, Form, Col } from 'react-bootstrap';
import { Row } from 'react-foundation';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useState, useEffect } from 'react';
import axios from 'axios';

function Header(props, state) {

  const dispatch = useDispatch();

  function onLogout() {
    dispatch(logout(props.history));
  }


  let PageTitle = 'Family Portal';
  if (props.location.pathname === '/profile') {
    PageTitle = 'My Profile';
  }
  if (props.location.pathname === '/reset-password') {
    PageTitle = 'Reset Password';
  }
  if (props.location.pathname === '/session-report') {
    PageTitle = 'Session Report';
  }

  toast.configure();

  let errorsObj = { message: '', contact: '' };

  const [errors, setErrors] = useState(errorsObj);
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState();

  function handleShow(breakpoint) {
    setFullscreen(breakpoint);
    setShow(true);
  }

  let ClientDetailsString = JSON.parse(localStorage.getItem('clientDetails'));
  let UserDetailsString = JSON.parse(localStorage.getItem('userDetails'));

  let AuthUserRecord = { first_name: '', last_name: '', franchise_contact: '' };
  let AuthClientRecord = { client_number: '' };

  if (UserDetailsString != null) {

    AuthUserRecord = {
      "first_name": UserDetailsString.first_name,
      "last_name": UserDetailsString.last_name,
      "franchise_contact": UserDetailsString.franchise_contact,
      "franchise_email": UserDetailsString.franchise_email
    };
  }

  if (ClientDetailsString != null) {
    AuthClientRecord = {
      "client_number": ClientDetailsString.client_number
    };
  }

  const [contact, setContact] = useState(AuthClientRecord ? AuthClientRecord.client_number : '');


  useEffect(() => {
    setContact(AuthClientRecord.client_number);
  }, [AuthClientRecord.client_number]);

  // for form reset 
  function resetForm() {
    setMessage('');
    setContact(AuthClientRecord.client_number);

    const errorObj = { ...errorsObj };

    let error = false;
    if (errors.message !== '') {
      errorObj.message = '';
      error = true;
    }
    if (errors.contact !== '') {
      errorObj.contact = '';
      error = true;
    }

    setErrors(errorObj);

    if (error) return; //return if exist any error 
  }

  function submitRequest() {

    // pass value of form element 
    let item = { message: message ? message : '', contact: contact }

    let error = false;

    const errorObj = { ...errorsObj };

    // validation error display 
    if (item.message === '') {
      errorObj.message = 'Message Is Required';
      error = true;
    }

    if (item.contact === '') {
      errorObj.contact = 'Contact Number Is Required';
      error = true;
    }

    setErrors(errorObj);

    if (error) return; //return if exist any error 

    // pass value to server.js for email send 
    const dataSend = {
      message,
      toEmail: AuthUserRecord.franchise_email,
      clientNumber: item.contact ? item.contact : AuthClientRecord.client_number
    }

    axios.post("/requestCallMail", dataSend).then(response => {
      if (response) {
        toast.success('Your Mail Sended Successfully..!');    // response of after send mail to franchisee
      }
    }).catch(error => {
      toast.error(error.response.data);    // error 404 pass to client side display with message
    });

    setMessage(''); // for empty value of message 
    setContact(AuthClientRecord.client_number); // default value set of contact 
    setShow(false); // for hide modal after submit
  }

function formatPhoneNumber(phoneNumberString) {
    const cleaned = (phoneNumberString).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ')-' + match[2] + '-' + match[3];
    }
    return null;
  }
  return (
    <div className="header">
      <div className="container-fluid">
        <div className="header-body">
          <div className="row centers">
            <div className="col">
              <h1 className="header-title">{PageTitle}</h1>
            </div>
            <div className="col-auto right-side">
              <h1 className="header-call">
                {AuthUserRecord.franchise_contact ?
                  <span>
                    Call:&nbsp;
                    <a href={'tel:' + AuthUserRecord.franchise_contact}>
                      {formatPhoneNumber(AuthUserRecord.franchise_contact)}
                    </a>
                  </span> : ''}
              </h1>

              {/* Request Call Button*/}
              <a className="btn btn-primary lift" onClick={() => handleShow()}>
                <i className="fb fb-request-call" /> Request a Call
              </a>

              {/* Modal Form for request send to franchisee */}
              <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                  <Modal.Title id="example-modal-sizes-title-sm">
                    Request a Call
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <form>
                    <Row>
                      <Col sm="12">
                        <Form.Group as={Row} className="mb-3">
                          <Form.Label column sm="4">
                            Contact No: <span className="text-danger">*</span>
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control type="text" name="contact" value={contact ? contact : ''} id={"contact"} onChange={e => setContact(e.target.value)} />
                            {errors.contact && <div className="error-text">{errors.contact}</div>}
                          </Col>
                        </Form.Group>
                      </Col>

                      <Col sm="12">
                        <Form.Group as={Row} className="mb-3" >
                          <Form.Label column sm="4">Message: <span className="text-danger">*</span></Form.Label>
                          <Col >
                            <Form.Control as="textarea" sm="8" name="message" value={message ? message : ''} id={"message"} onChange={e => setMessage(e.target.value)} rows={3} />
                            {errors.message && <div className="error-text">{errors.message}</div>}
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <div className="col-lg-12 mt-1 sa-ca text-right">
                        <button type="reset" onClick={submitRequest} className="btn btn-bang-save">Save</button>
                        <button type="button" onClick={resetForm} className="btn btn-bang-save">Cancel</button>
                      </div>
                    </Row>
                  </form>
                </Modal.Body>
              </Modal>
              <div className="col-auto right-side">
                


                  <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                    <Dropdown.Item href="javascript:void(0);" className="dropdown-item d-flex align-items-center p-3">
                      <img className="avatar avatar-sm p-1 me-2" src="../images/Profile_Pic.png" alt="Jason Doe" />
                      <div className="pt-1 space-left">
                        <h6 className="fw-bold mb-0">Coming Soon</h6>
                        <span className="text-muted text-sm">Coming Soon</span>
                      </div>
                    </Dropdown.Item>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item text-center" href="#">
                      <small class="fw-bold text-uppercase"> View all messages </small>
                    </a>

                  </Dropdown.Menu>
                              </div>


              <div className="navbar-user md-block">
                <Dropdown>
                  <Dropdown.Toggle variant="" id="dropdown-basic" className="profile-dropdown">
                    <div className="avatar avatar-sm avatar-online">
                      <span>
                        {AuthUserRecord.first_name.charAt(0)}{AuthUserRecord.last_name.charAt(0)}
                      </span>
                    </div>
                  </Dropdown.Toggle>


                  <Dropdown.Menu>

                    <Dropdown.Item >
                      <p>Welcome,<br />{AuthUserRecord.first_name} {AuthUserRecord.last_name} !</p>
                    </Dropdown.Item>
                    <div class="dropdown-divider"></div>
                    <Dropdown.Item href="/profile">Profile</Dropdown.Item>
                    <Dropdown.Item href="/reset-password">Settings</Dropdown.Item>
                    <Dropdown.Item href="javascript:void(0);" onClick={onLogout}>Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >



  );
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
  };

};

export default withRouter(connect(mapStateToProps)(Header));
